<template>
  <div>
    <el-card>
      <el-tag
        size="medium"
        style="
          margin-bottom: 50px;
          line-height: 30px;

          height: 30px;
          text-align: center;
          font-size: 14px;
        "
      >用户基本信息</el-tag>
      <el-form ref="form" :model="sysConfig" label-width="120px">

        <el-form-item label="职位名称" prop="name">
          <el-col :span="8">
            <el-input v-model="sysConfig.name" />
          </el-col>
        </el-form-item>

      </el-form>
    </el-card>
    <el-button
      type="primary"
      style="margin-top: 50px"
      @click="editform"
    >确定保存</el-button>
    <el-button
      type="primary"
      style="margin-top: 50px; width: 98px"
      @click="rest"
    >取&nbsp;&nbsp;&nbsp;&nbsp;消</el-button>
  </div>
</template>

<script>
import $ from 'jquery'
// import {WebUploader} from '../../assets/webuploader'

export default {
  data() {
    return {
      sysConfig: {
        name: ''
      },

      imageUrl: '',
      header: {
        Token: sessionStorage.getItem('token')
      },
      baseurl: process.env.VUE_APP_STATIC
    }
  },
  created() {
    // this.getform()
  },
  mounted: function() {},
  methods: {
    rest() {
      // this.form={
      //   logo: '',
      //   keep_record: '',
      //   ipc: '',
      //   copyright: '',
      // }
      this.$refs.form.resetFields()
      this.imgsrc = ''
    },
    getform() {
      this.$http.get('/admin/SysConfig/getDetail').then(({ data: res }) => {
        console.log(res)
        if (res.errorCode == 200) {
          if (res.data.sys_config) {
            // console.log(res)
            this.sysConfig = res.data.sys_config
          }
        }
      })
    },

    editform() {
      this.$http
        .post('/admin/AuthGroup/add', this.sysConfig)
        .then(({ data: res }) => {
          if (res.errorCode == 200) {
            this.$message.success(res.message)
            $(this.$refs.sde).click()
            // location.reload()
            // this.getform()
            this.$router.push('/system_auth_group')
          } 
        })
    },
    handleAvatarSuccess(res, file) {
      console.log(res, file)
      this.sysConfig.logo = this.baseurl + res.data.url
    }
  }
}
</script>

<style lang="less" scoped>
.img {
  // width: 500px;
  height: 200px;
  width: auto;
  // height: 200px;
  border: 0;
  outline: 0;
}
img[src=''] {
  opacity: 0;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  // width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  // width: 500px;
  height: 178px;
  display: block;
}
</style>
