import { render, staticRenderFns } from "./system_auth_group_add.vue?vue&type=template&id=fc0b946c&scoped=true&"
import script from "./system_auth_group_add.vue?vue&type=script&lang=js&"
export * from "./system_auth_group_add.vue?vue&type=script&lang=js&"
import style0 from "./system_auth_group_add.vue?vue&type=style&index=0&id=fc0b946c&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fc0b946c",
  null
  
)

export default component.exports